import { AfterViewInit, Component } from '@angular/core';
@Component({
    selector: 'kf-hubint-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent implements AfterViewInit {
    constructor() {
        // temporary fix. Need to do this on kfhub_lib
        localStorage.setItem('auth_and_redirect_url', 'dashboard')
    }
    
    ngAfterViewInit() { 

    }
}
