import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AppPermissionsGuard } from './app-permissions.guard';
import { AppResolver } from './app.resolver';

export const routes: Routes = [
    { path: 'login', component: LoginComponent },
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AppPermissionsGuard],
        resolve: {
            UserAccessList: AppResolver,
        },
    },
    {
        path: 'permissions',
        loadChildren: () => import('./permissions/permissions.module').then(m => m.PermissionsModule),
        canActivate: [AppPermissionsGuard],
    },
    {
        path: 'competencies',
        loadChildren: () => import('./workday/competencies/competencies.module').then(m => m.CompetenciesModule),
        canActivate: [AppPermissionsGuard],
    },
    {
        path: 'validation',
        loadChildren: () => import('./validation/validation.module').then(m => m.ValidationModule),
        canActivate: [AppPermissionsGuard],
    },
    {
        path: 'paydata',
        loadChildren: () => import('./dct-management/paydata.module').then(m => m.PayDataModule),
        canActivate: [AppPermissionsGuard],
    },
    {
        path: 'pdc-validations-t2',
        loadChildren: () => import('./pdc-validations/pdc-validations-t2/pdc-validations-t2.module').then(m => m.PdcValidationsT2Module),
        canActivate: [AppPermissionsGuard],
    },
    {
        path: 'pdc-validations-t3',
        loadChildren: () => import('./pdc-validations/pdc-validations-t3/pdc-validations-t3.module').then(m => m.PdcValidationsT3Module),
        canActivate: [AppPermissionsGuard],
    },
    {
        path: 'kfutils',
        loadChildren: () => import('./kfutils/kfutils.module').then(m => m.KFUtilsModule),
        canActivate: [AppPermissionsGuard],
    },
    {
        path: 'pay-reports',
        loadChildren: () => import('./pay-reports/reports.module').then(m => m.ReportsModule),
        canActivate: [AppPermissionsGuard],
    },
    {
        path: 'benefits-prevalence',
        loadChildren: () => import('./benefits-prevalence/benefits-prevalence.module').then(m => m.BenefitsPrevalenceModule),
        canActivate: [AppPermissionsGuard],
    },
    {
        path: 'th-management',
        loadChildren: () => import('./talent-hub-management/talent-hub-management.module').then(m => m.TalentHubManagementModule),
        canActivate: [AppPermissionsGuard],
    },
    {
        path: 'banners',
        loadChildren: () => import('./banners/banners.module').then(m => m.BannersModule),
        canActivate: [AppPermissionsGuard],
    },
    {
        path: 'email-management',
        loadChildren: () => import('./email/email.module').then(m => m.EmailModule),
        canActivate: [AppPermissionsGuard],
    },
    {
        path: 'sector-coding-tool',
        loadChildren: () => import('./sct/sct.module').then(m => m.SctModule),
        canActivate: [AppPermissionsGuard],
    },
    {
        path: 'pdc-t2-file-upload',
        loadChildren: () => import('./pdc-t2-file-upload/pdc-t2-file-upload.module').then(m => m.PdcT2FileUploadModule),
        canActivate: [AppPermissionsGuard],
    },
    {
        path: 'qualtrics',
        loadChildren: () => import('./qualtrics/competencies/competencies.module').then(m => m.CompetenciesModule),
        canActivate: [AppPermissionsGuard],
    },
    {
        path: 'ris-reports',
        loadChildren: () => import('./ris-reporting/ris-reporting.module').then(m => m.RisReportingModule),
        canActivate: [AppPermissionsGuard],
    },
    { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(routes, {}),
    ],
    exports: [
        RouterModule,
    ],
    providers: [
        AppPermissionsGuard, AppResolver,
    ],
    declarations: [],
})
export class AppRoutingModule { }
